:root {
    /*Margins & Paddings*/
    --padding: 1em;
    --margin: 1em;
    --vertical-padding: 1em; /* must NOT be a percentage value */
    --vertical-margin: 1em;  /* must NOT be a percentage value */
    
     /*Breakpoints*/
    --breakpoint-m: 1100px;
    --breakpoint-s: 800px;
    --breakpoint-xs: 550px;
    --breakpoint-menu: 800px;
    
     /*General*/
    --container-max-width: 1100px;
    --fixed-header-height: 80px;
    --base-font-family: 'Poppins';
    --alternative-font-family: 'Poppins';
    
    /* change settings for headings */
    --header-bg-color: #2C71C1;
    --header-padding:1em 0;
    
    /* change colors for topmenu */
    --topmenu-bg: var(--link-color);
    --topmenu-color: white;
    --topmenu-li-a-padding: 10px 15px;

    /* Typo */
    --scale-factor: 1.618;
    --base-font-size: 14px;
    --base-line-height: 1.5;
    --scale-factor-m: var(--scale-factor);
    --scale-factor-s: 1.418;
    --scale-factor-xs: 1.218;
    
    /*Colors & Backgrounds*/
    --text-color: #636363;
    --heading-color: var(--link-color);
    --link-color: #222;
    --link-hover-color: #000;
    --alternative-color: #ff0000;
    --f-color: #fff;
    --map-marker-color: 000000;
}

@import "/css/normalize.css";
    @import "/cssmodules/leaflet2.css";
    @import "/css/basic_styling.css";
@import "/css/flex_grid.v1.1.css";
@import "/css/slick.css";
@import "/css/slick-theme.css";
@import "/css/menu.v1.2.css";
@import "/css/magnific-popup.css";
@import "/fonts/Open Sans/all.css"; /* 300, 300, 300i, 300i, 400, 400, 400, 400i, 400i, 400i, 700, 700i */
@import "/fonts/Roboto/all.css"; /* 100, 100i, 300, 300i, 400, 400i, 500, 500i, 700, 700i, 900, 900i */
@import "/fonts/Poppins/all.css"; /* 300, 400, 500, 600, 700 */
/*@import "/cssmodules/animate.css";*/


/* General bg-colors
---------------------------------------------------------*/

.maincolor {
    background-color: var(--link-color);
}

.white {
    background-color:white;
}

.lightblue {
    background-color:#f0f5f8;
}

.darkblue {
    background-color:#2C71C1;
}

.darkgray {
    background-color:#222;
}

.f-color {
    color:var(--f-color);
}

.red {
    background-color:#ff0000;
}


/* Header
---------------------------------------------------------*/
header {
    background:var(--header-bg-color);
    text-transform:none;
    padding:var(--header-padding);
    box-shadow: 0 2px 3px 0 rgba(0,0,0,0.2);
}

header .container {
    padding:0 0.5em;
}
.startbox-wrapper .readmore a{color:#fff;}

header a {
    color:black;
}

.menu li {
    margin:0;
    padding:var(--topmenu-li-a-padding);
    font-family:var(--alternative-font-family);
}

.menu li a {
    padding:0;
    color:#cbe3ff;
    text-transform:uppercase;
    position: relative;
}

.menu li a:hover, .menu .active a {
    color:white;
}

nav >.menu > li >a:before {
    content: "";
    position: absolute;
    bottom: 0;
    background: #cbe3ff;
    height: 2px;
    left: 0;
    right: 0;
    transform: scale(0, 1);
    transition: transform 0.3s ease-out;
}

.menu li a:hover:before, .menu .active a:before {
    transform: scale(1, 1);
    background:white;
}

.menu .dropdown ul {
    background:#222222;
    z-index:100;
}

.menu li:hover ul {
    margin-left:0;
}

.menu .dropdown ul {
    width:450px;
    border-bottom:0;
    padding:1.5em 0;   
}

.menu .dropdown li {
    padding:0 0.5em;
}

.menu .dropdown li a {
    padding:10px 15px;
    color:#fff;
    border-right:none;
    border-bottom:#464646 dotted 1px
}

.menu .dropdown li a:hover, .menu .dropdown .active a {
    background:#222222;
    color:#a6a6a6;
}

.menu .sub-menu-items li a {
    margin:0;
}

.logo {
    padding:0;
}

.logo a {
    font-size:0;
}

/*--------------------------------------------------------------
Specialbutton
--------------------------------------------------------------*/
a.button.specialbutton {
    background:  #1a1a1a;
    font-size: 1.5rem;
    border: #000000 solid 2px;
    box-shadow: 0 4px 4px rgba(0,0,0,.4);
    width: 35%;
    margin-top: 1em;
    color: #fff;
    font-weight: 500;

}
.button {
    margin: 0;
    background: #222;
    border: #222 solid 1px;
    color: white;
    border-radius: 20px;
    width: 40%;
}
.button, button, input[type=reset], input[type=submit] {
    text-decoration: none;
    border-radius: .2em;
    cursor: pointer;
    display: inline-block;
    margin: 1em;
    padding: .5em 1em;
    text-align: center;
    outline: 0;
    border: 2px solid;
    background: 0 0;
}


/* bildspel
---------------------------------------------------------*/

.slick {
    position: relative;
    margin-bottom: 0;
}

.slick-track {
    display: flex;
    flex-direction: row;
}   

.homepage .slick,
.homepage .slick-slide,
.homepage .slick-substitute > div {
    height:100%;
    min-height: 75vh;
}

.homepage .slick-slide,
.homepage .slick-substitute > div {
    background-size: cover;
    background-position: center center;
}

.slick-slider {
    margin:0 auto;
    position: relative;
}

.homepage .slick-slide,
.homepage .slick-substitute > div {
    background-size:cover;
    background-position: center center;
    display: flex !important;
    align-items: center;
    justify-content: center;
} 

.slick-title {
    text-align:center;
    font-size: calc(1 * var(--scale-factor-s)rem);
}

.slick-title .small {
    max-width:680px;
    width:100%;
    margin:0 auto;
    padding:2em;
    background:rgba(255,255,255,0.7);
    color:black;
}

.slick-title h2 {
    display:block;
    font-size: calc(1 * var(--scale-factor) * var(--scale-factor)rem);
    color:#2C71C1;
    font-weight:700;
    margin-bottom:0;

}

.slick-title .start-btn {
    margin-top:1.5em;
    position:relative;
    z-index:10;
}

.slick-title .start-btn a {
    background:transparent;
    border:white solid 1px;
}

.slick-title .start-btn a:hover {
    background: #2C71C1;
    border:#2C71C1 solid 1px;
    color:white;
}

.slick-prev, .slick-next {
    width:auto;
    height:auto;
}

.slick-next {
    right:50px;
    z-index:100;
}

.slick-prev {
    left:50px;
    z-index:100;
}

.slick-next, .slick-prev {
    opacity:1;
}

.slick-next:hover, .slick-prev:hover {
    border:none;
}

.slick-next:before, .slick-prev:before {
    transition: 0.8s ease;
    -o-transition: 0.8s ease;
    -webkit-transition: 0.8s ease;
   background:rgba(0,0,0,0.7);
    padding:1.5em;
    font-family: Roboto;
    line-height:normal;
}

.slick-next:hover:before, .slick-prev:hover:before {
    background:white;
    color:black;
}

.slick-next:before {
    content:"»";
}

.slick-prev:before {
    content:"«";
}

.slick-dots {
    bottom:0px;
}

.slick-dots li button:before {
    font-size:0rem;
    opacity:1.0;
    color: var(--alternative-color);
    width:40px;
    height:8px;
}

.slick-dots li.slick-active button:before {
    background:var(--alternative-color);
    display:block;
}

.slick-dots li {
    border:var(--alternative-color) solid 1px;
    border-radius:0%;
    margin:0 2px;
    width:40px;
    height:8px;
}

/* Content-startpage
---------------------------------------------------------*/
.block-2  {
    padding:2em 0;
}

.block-2 .container .inner-content {
    max-width:800px;
    margin:0 auto;
    text-align:center;
    border-bottom:#eee solid 1px;
}

.block-2 .container .inner-content h1 {
    font-size: calc(0.8 * var(--scale-factor) * var(--scale-factor)rem);
    text-transform:uppercase;
}

.boxwrapper {
    min-height: 25vh;
    background-size: 0% 0%;
    position: relative;
    overflow: hidden;
    text-decoration: none;
}

.boxwrapper h2 {
    color: #fff;
    margin: 0;
    text-align:center;
    text-transform: uppercase;
    text-shadow: 1px 1px 2px rgba(0,0,0,0.5);
    z-index: 3;
    font-weight: 400;
}

.zoombox .boxwrapper[style]:before, .boxwrapper:after, .boxwrapper figure img, .boxwrapper figure figcaption {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
}

.zoombox .boxwrapper[style]:before {
    content: '';
    background-image: inherit;
    background-size: cover;
    background-position: center center;
    transition: transform .65s ease-in 0s;
}

.zoombox .boxwrapper figure img {
    transition: transform .65s ease-in 0s;
}

.zoombox .boxwrapper:after {
    border: 50px solid rgba(255, 243, 1, 0.1);
    border-radius: 50%;
    box-sizing: border-box;
    cursor: pointer;
    display: inline-block;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    position: absolute;
    top: 0;
    content: "";
    opacity: 1;
    filter: alpha(opacity=100);
    width: 100px;
    height: 100px;
    transform: scale(7);
    transition: all 0.7s ease-in-out 0s;
    visibility: visible;
    z-index: 1

}

.zoombox .boxwrapper:hover:after {
    border-width: 0;
    opacity: 0;
    filter: alpha(opacity=0);
    visibility: hidden;
}

.zoombox .boxwrapper[style]:hover:before, .boxwrapper:hover figure img {
    transform: scale(1.3);
    transition: transform .65s ease-out 0s;
}

.zoombox .box-teaser {
    z-index:101;
    opacity:0;
    text-align:center;
    transition: 0.5s ease;
    color:white;
    max-width:400px;
    width:100%;
    min-height:25px;
}

.zoombox .boxwrapper:hover .box-teaser {
    opacity:1;
}



/* listing items
---------------------------------------------------------*/
.listing-item {
    margin-bottom:1.5em;
}

.listing-item p:first-of-type {
    padding-top:0;
    margin-top:0;
}

.listing-item h2 {
    margin-bottom:0;
}

.pagination {
}

.pagination ul {
    float: left;
    clear: both;
    display: block;
    margin: 8px 0;
    padding: 0;
    border: 1px solid #DDD;
}

.pagination ul li {
    float: left;
    list-style-type: none;
    border-right: 1px solid #DDD;
    padding: 4px 6px;
}

.pagination ul li:last-child {
    border-right: none;
}

.pagination ul li.active {
    font-weight: bold;
    background-color: var(--link-color);
}

.pagination ul li.active a {
    color:white;
}

.pagination ul li a {
    text-decoration: none;
}

/* Produkter 
----------------------------------------------------------*/
.slider-nav  {
    margin-top:0.4em;
}

.item-box {
    text-decoration:none;
}

.item-box .startbox-wrapper {
    transition: 0.3s;
    box-shadow: 0 0 0 1px #eee;
}

.item-box .startbox-wrapper:hover {
    box-shadow: 0 8px 12px 0 rgba(0,0,0,0.2)
}


.item-box .startbox-wrapper h2 {
    margin-top:1em;
    text-align:center;
}

.produkter .item-box .startbox-wrapper {
    border:#eee solid 1px;
}

.produkter a {
    text-decoration:none;
}

.startbox-wrapper .startbox-inner  {
}

.pop-img {
    position:relative;
    background-size:contain;
    background-repeat:no-repeat;
    background-position:top center;
    min-height:197px;
    display:flex;
    align-items:center;
}

.startbox-wrapper .readmore {
    color:white;
    padding:1em 2em;
        text-align: center;
    font-size: 1.1rem;
}

.reduced {
    display:inline-block;
    padding:1em;
    color:white;
}

    
/* Content
---------------------------------------------------------*/

.content.margin > h1, .content.margin > .art-img {
    margin-left:0 ;
}

.content h1 {
    margin-bottom:0;
}

.art-content p:first-of-type {
    margin-top:0;
}

table, tbody {
    width:100%;
    margin-top:0;
}

table td p {
    margin:0;
}

table td {
    border-color:#cecece;
    padding:0.4em;
}

tbody tr:nth-child(even) {
   background-color: #eee;
}

/* Contact
---------------------------------------------------------*/

body.kontakt {
    background:#f5f5f5;
}

.contact-block {
    box-shadow: 0px 0px 0px 1px #ececec;
}

.contact-block-l, .contact-block-2 {
    padding: var(--padding);
}

.contact-block-2 > div {
    border-bottom:#eee solid 1px;
    padding:1.5em 0;
}

.contact-block-2 .contactinfo p {
    margin:0;
}

.contact-block-2>div a {
    text-decoration:none;
}

.art-img {
    margin-right:0;
}

figure {
    margin:0;
}
    

/* Footer
---------------------------------------------------------*/

.footer-form  {
    color:#636363;
}

footer h4 {
    color:var(--f-color);
    font-size:1.3rem;
    letter-spacing:0;
}

footer a {
    text-decoration:none;
}

footer svg.fa-icon {
    fill:white;
    height:calc(1 * var(--scale-factor)rem);
}

footer svg.fa-icon:hover {
    fill: var(--link-hover-color);
}

/*Google maps*/
.map-canvas {
    height: 30vh;
}

.map-canvas img {
    max-width: none;
}
 
/*Intendit footer*/
.created-by {
    background: #2763aa;
    border-top: #2763aa solid 1px;
    padding:0.5em 0;
    font-size:0.8rem;
}

.created-by .container, .intenditfooter {
    padding:0;
    margin:0 auto;
}

.created-by .container .intenditfooter, .created-by .container .intenditfooter a  {
    color:#fff;
}

.intenditfooter >* {
    margin: var(--margin);
}

/* sticky footer */
.site {
    display: flex;
    flex-direction: column;
    height: 100%;
}

main {
    flex: 1 0 auto;
    min-height: 1%; /* IE fix */
}

header, footer {
    flex: none;
}

footer .contactinfo p {
    margin:0;
}


/* general
---------------------------------------------------------*/

.boltform textarea {
    min-height:120px;
}

input[type=color],
input[type=date],
input[type=datetime-local],
input[type=datetime],
input[type=email],
input[type=file],
input[type=month],
input[type=number],
input[type=password],
input[type=phone],
input[type=range],
input[type=search],
input[type=tel],
input[type=text],
input[type=time],
input[type=url],
select,
textarea {
    width: 100%;
    color:#222;
    border-radius:0;
    padding:0.5em;
}

button[type=submit], input[type=submit], .button, button {
    margin:0;
    background:var(--link-color);
    border:var(--link-color) solid 1px;
    color:white;
    border-radius:0;
}

button[type=submit]:hover, input[type=submit]:hover, .button:hover, button:hover {
    background: #2C71C1;
    border:#2C71C1 solid 1px;
    color:white;
}


img {
    max-width: 100%;
    height: auto;
}

.flex > * > img {
    flex: 0 0 auto;
}
/* cross-browser fix, obs! gäller endast för bilder i div utan klass*/
.flex > * > img:not([class]) {
    width: 100%;
}

.container {
    max-width: var(--container-max-width);
    padding: 0.5em;
    width: 100%;
}

.container.lgr {
    max-width:75%;
    width:100%;
}

html {
    font-size: var(--base-font-size);
    line-height: var(--base-line-height);
    height:100%;
}

body {
    font-family: var(--base-font-family);
    font-weight: 400;
    color: var(--text-color);
    font-size: var(--base-font-size);
    line-height: var(--base-line-height);
}

h1, h2, h3, h4, h5, h6 {
    font-family: var(--alternative-font-family);
    letter-spacing:-1px;
    color: var(--heading-color);
    margin:0 0 0.3em 0;
}

h1 {
    font-size: calc(1 * var(--scale-factor) * var(--scale-factor)rem);
}

h2 {
    font-size: calc(1 * var(--scale-factor)rem);
}

h3 {
    font-size: 1.2rem;
}

a {
    color: var(--link-color);
    transition: 0.5s ease;
    -o-transition: 0.5s ease;
    -webkit-transition: 0.5s ease;
}

a:hover {
    color: var(--link-hover-color);
}

.markerLabels{
    overflow:visible !important;
}

.fa-map-marker{
    background: url('data:image/svg+xml;utf8,%3Csvg%20fill%3D%22%23var(--map-marker-color)%22%20width%3D%221792%22%20height%3D%221792%22%20viewBox%3D%220%200%201792%201792%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20d%3D%22M1152%20640q0-106-75-181t-181-75-181%2075-75%20181%2075%20181%20181%2075%20181-75%2075-181zm256%200q0%20109-33%20179l-364%20774q-16%2033-47.5%2052t-67.5%2019-67.5-19-46.5-52l-365-774q-33-70-33-179%200-212%20150-362t362-150%20362%20150%20150%20362z%22%2F%3E%3C%2Fsvg%3E');
    background-size: cover;
    padding: 10px;
    background-position: center;
}



/* Media Queries
---------------------------------------------------------*/
 
@media (max-width: var(--breakpoint-menu)) {
    .menu.show, .menu {
        background:#2C71C1 !important;
        box-shadow: 0 4px 2px -2px gray;
    }
    .container.lgr {
        max-width:100%;
    }
    #menu-icon:before, #menu-icon:after, #menu-icon span {
        color:white;
    }
}

@media (max-width: var(--breakpoint-m)) {
      
    h1 {
        font-size: calc(1 * var(--scale-factor-m) * var(--scale-factor-m)rem);
    }

    h2 {
        font-size: calc(1 * var(--scale-factor-m)rem);
    }
}

@media (max-width: var(--breakpoint-s)) {
    
    h1 {
        font-size: calc(1 * var(--scale-factor-s) * var(--scale-factor-s)rem);
    }

    h2 {
        font-size: calc(1 * var(--scale-factor-s)rem);
    }
}

@media (max-width: var(--breakpoint-xs)) {
    h1 {
        font-size: calc(1 * var(--scale-factor-xs) * var(--scale-factor-xs)rem);
    }

    h2 {
        font-size: calc(1 * var(--scale-factor-xs)rem);
    }
    footer.container {
        text-align:center;
    }
    footer .container {
        text-align:center;
    }
    
}


.slick:not(.slick-slider) > div:not(:first-child) {
    display: none;
}

.slick:not(.slick-slider) > div {
    height: 100%;
    min-height: 100vh;
}
